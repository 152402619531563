<template>
  <CreateUpdateTemplate
    :customClass="'leave-create'"
    v-if="getPermission('leave:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Create new Leave</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate"
        color="cyan"
      >
        Save Leave
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="leaveForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate"
      >
        <v-container fluid>
          <!-- <perfect-scrollbar
            :options="{ suppressScrollX: true }"
            class="scroll custom-box-top-inner-shadow"
            style="max-height: 70vh; position: relative"
          > -->
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="200">Engineer</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Engineer"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="userList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leaveCreate.engineer"
                      label="Engineer"
                      solo
                      flat
                      item-color="cyan"
                      item-text="display_name"
                      item-value="id"
                      hide-details
                      :rules="[
                        validateRules.required(
                          leaveCreate.engineer,
                          'Engineer'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title
                            v-html="'No Engineer(s) Found.'"
                          ></v-list-item-title>
                        </v-list-item>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(item.profile_logo)"
                            aspect-ratio="1"
                            class="margin-auto grey lighten-5 custom-grey-border"
                            transition="fade-transition"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >#{{ item.barcode }}</v-list-item-title
                          >
                          <v-list-item-title
                            class="text-capitalize font-weight-500 font-size-16"
                            >{{ item.display_name }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action class="align-self-center">
                          <v-list-item-subtitle
                            class="text-lowercase pb-2 font-weight-500 font-size-14"
                            >{{ item.primary_email }}</v-list-item-subtitle
                          >
                          <v-list-item-subtitle
                            class="text-lowercase font-weight-500 font-size-14"
                            >{{ item.primary_phone }}</v-list-item-subtitle
                          >
                        </v-list-item-action>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Leave Type</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Leave Type"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-autocomplete
                      dense
                      color="cyan"
                      filled
                      :items="leaveTypeList"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="leaveCreate.leave_type"
                      label="Leave Type"
                      solo
                      flat
                      item-color="cyan"
                      hide-details
                      :rules="[
                        validateRules.required(
                          leaveCreate.leave_type,
                          'Leave Type'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Leave Type Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="200">Team Email ID</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Team Email ID"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <v-text-field
                      v-model.trim="leaveCreate.team_email_id"
                      dense
                      filled
                      label="Team Email ID"
                      solo
                      flat
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      color="cyan"
                      :rules="[
                        validateRules.required(
                          leaveCreate.team_email_id,
                          'Team Email ID'
                        ),
                        validateRules.validEmail(
                          leaveCreate.team_email_id,
                          'Team Email ID'
                        ),
                        validateRules.minLength(
                          leaveCreate.team_email_id,
                          'Team Email ID',
                          1
                        ),
                        validateRules.maxLength(
                          leaveCreate.team_email_id,
                          'Team Email ID',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td class="font-size-16 pb-2" width="150">Leave number</td>
                  <td
                    class="font-weight-700 font-size-16 pb-4"
                    colspan="2"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>
                      <v-text-field
                        dense
                        color="cyan"
                        filled
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        readonly
                        v-model.trim="leaveCreate.barcode"
                        label="Leave number"
                        solo
                        flat
                        hide-details
                        class="remove-border cursor-default"
                      ></v-text-field>
                    </template>
                  </td>
                  <td class="font-size-16 pb-4">
                    <v-btn
                      text
                      small
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="barcodeDialog = true"
                      color="cyan"
                    >
                      Change
                    </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="150">Leave duration</td>
                  <td class="font-weight-700 font-size-16 pb-4" colspan="3">
                    <v-radio-group
                      v-model="leaveCreate.leave_duration"
                      mandatory
                      row
                      hide-details
                      class="m-0"
                    >
                      <v-radio
                        label="Full Day"
                        value="full-day"
                        color="cyan"
                      ></v-radio>
                      <v-radio
                        label="Half Day"
                        value="half-day"
                        color="cyan"
                      ></v-radio>
                    </v-radio-group>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 pb-2" width="150">Start Date</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <DatePicker
                      solo
                      mandatory
                      :pageLoading.sync="pageLoading"
                      placeholder="Start Date"
                      v-model="leaveCreate.leave_start_date"
                    ></DatePicker>
                  </td>
                  <td class="font-size-16 pb-2 px-4" width="150">
                    <template v-if="leaveCreate.leave_duration == 'full-day'">
                      End Date
                    </template>
                  </td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Date"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <template v-if="leaveCreate.leave_duration == 'full-day'">
                      <DatePicker
                        solo
                        mandatory
                        :pageLoading.sync="pageLoading"
                        placeholder="End Date"
                        v-model="leaveCreate.leave_end_date"
                      ></DatePicker>
                    </template>
                  </td>
                </tr>
                <tr v-if="leaveCreate.leave_duration == 'half-day'">
                  <td class="font-size-16 pb-2" width="150">Start Time</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="Start Time"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <TimePicker
                      solo
                      mandatory
                      :pageLoading.sync="pageLoading"
                      placeholder="Start Time"
                      v-model="leaveCreate.leave_start_time"
                    ></TimePicker>
                  </td>
                  <td class="font-size-16 pb-2 px-4" width="150">End Time</td>
                  <td
                    colspan="2"
                    class="pb-4"
                    content="End Time"
                    v-tippy="{ placement: 'top-start' }"
                  >
                    <TimePicker
                      solo
                      mandatory
                      :defaultTime.sync="endTime"
                      :pageLoading.sync="pageLoading"
                      placeholder="End Time"
                      v-model="leaveCreate.leave_end_time"
                    ></TimePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" class="py-0">
              <editor v-model="leaveCreate.reason" />
            </v-col>
            <v-col md="12">
              <FileTemplate
                allowUpload
                v-on:file:updated="updateAttachment"
              ></FileTemplate>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
    <template v-slot:barcode-setting>
      <BarcodeSetting
        endpoint="leave"
        :barcodeDialog="barcodeDialog"
        :dialogWidth="600"
        :barcodeSetting.sync="barcodeSetting"
        v-on:update:barcode-setting="getOptions"
        v-on:close:barcode-dialog="barcodeDialog = false"
      ></BarcodeSetting>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import TimePicker from "@/view/pages/partials/Timepicker.vue";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CLEAR_ERROR, POST, GET } from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import moment from "moment-timezone";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "leave-create",
  title: "Create Leave",
  data() {
    return {
      pageLoading: false,
      barcodeDialog: false,
      barcodeSetting: new Object(),
      leaveTypeList: new Array(),
      userList: new Array(),
      leaveCreate: new Object({
        engineer: null,
        leave_duration: null,
        leave_type: null,
        leave_start_date: null,
        leave_end_date: null,
        leave_start_time: null,
        leave_end_time: null,
        reason: null,
        team_email_id: null,
        attachments: [],
      }),
    };
  },
  components: {
    DatePicker,
    TimePicker,
    FileTemplate,
    BarcodeSetting,
    CreateUpdateTemplate,
    editor: TinyMCE,
  },
  methods: {
    updateAttachment(param) {
      this.leaveCreate.attachments = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leave/options",
        })
        .then(({ data }) => {
          _this.barcodeSetting = data.barcode_setting;
          _this.leaveCreate.barcode = data.barcode;
          _this.userList = data.users;
          _this.leaveTypeList = data.leave_type;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateOrCreate() {
      const _this = this;

      const validateStatus = _this.$refs.leaveForm.validate();

      const formErrors = _this.validateForm(_this.$refs.leaveForm);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let formData = new Object({
        engineer: _this.leaveCreate.engineer || null,
        leave_duration: _this.leaveCreate.leave_duration || null,
        leave_type: _this.leaveCreate.leave_type || null,
        leave_start_date: _this.leaveCreate.leave_start_date || null,
        leave_end_date: _this.leaveCreate.leave_end_date || null,
        leave_start_time: _this.leaveCreate.leave_start_time
          ? moment(
              _this.leaveCreate.leave_start_date +
                " " +
                _this.leaveCreate.leave_start_time
            ).format("HH:MM")
          : null,
        leave_end_time: _this.leaveCreate.leave_end_time
          ? moment(
              _this.leaveCreate.leave_end_date +
                " " +
                _this.leaveCreate.leave_end_time
            ).format("HH:MM")
          : null,
        reason: _this.leaveCreate.reason || null,
        team_email_id: _this.leaveCreate.team_email_id || null,
        attachments: _this.leaveCreate.attachments || [],
      });

      _this.$store
        .dispatch(POST, {
          url: "leave",
          data: formData,
        })
        .then(({ data }) => {
          _this.$router.push(
            _this.getDefaultRoute("leave.detail", {
              params: {
                id: data.id,
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  computed: {
    endTime() {
      return moment().add(4, "hours").format("hh:mm A");
    },
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leave", route: "leave" },
      { title: "Create" },
    ]);
  },
};
</script>
